<template>
  <div>
    <CustomInput
      v-model="billingInformation.emailAddress"
      :label="$t('email_general')"
    ></CustomInput>

    <CompanyFormContact
      :contact="billingInformation.contact"
    ></CompanyFormContact>

    <b-field horizontal>
      <b-checkbox v-model="mimicsAddress" class="mt-5">
        {{ $t("same_as_address") }}
      </b-checkbox>
    </b-field>

    <CompanyFormAddress
      :address="billingInformation.address"
      :disabled="mimicsAddress"
    ></CompanyFormAddress>
  </div>
</template>
<script>
import CustomInput from "../../../components/forms/CustomInput";
import CompanyFormAddress from "./CompanyFormAddress";
import CompanyFormContact from "./CompanyFormContact";

export default {
  components: {
    CompanyFormContact,
    CompanyFormAddress,
    CustomInput,
  },
  props: {
    billingInformation: {
      type: Object,
      required: true,
    },
    checkMimicsAddress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mimicsAddress: false,
    };
  },
  watch: {
    mimicsAddress(value) {
      this.$emit("mimics", value);
    },
  },
  mounted() {
    if (this.checkMimicsAddress) {
      this.mimicsAddress = true;
    }
  },
};
</script>
