<template>
  <b-field
    :label="$tc('user')"
    :label-for="`user${_uid}`"
    :horizontal="horizontal"
    class="user-autocomplete"
  >
    <b-taginput
      :id="`user${_uid}`"
      :ref="`user${_uid}`"
      v-model="selectedUser"
      :data="users"
      autocomplete
      field="name"
      maxtags="1"
      open-on-focus
      :loading="fetching"
      :has-counter="false"
      :before-adding="checkIfAllowed"
      @typing="fetchUsers"
    >
      <template #empty>
        <span v-if="fetching">{{ $t("loading") }}</span>
        <span v-else-if="query">
          {{ $t("no_matching_results") }}
        </span>
        <span v-else>{{ $t("user_search") }}</span>
      </template>

      <template slot-scope="props">
        <span :class="{ 'has-text-grey-light': userIsDisabled(props.option) }">
          {{ props.option.name }} ({{ props.option.emailAddress }})
        </span>
      </template>

      <template #selected="props">
        <b-tag
          v-for="(tag, index) in props.tags"
          :key="index"
          :tabstop="false"
          ellipsis
          closable
          @close="$refs[`user${_uid}`].removeTag(index, $event)"
        >
          {{ tag.emailAddress }}
        </b-tag>
      </template>
    </b-taginput>
  </b-field>
</template>

<script>
import { debounce, sortBy } from "lodash-es";
import { mapActions } from "vuex";

export default {
  props: {
    disabledUsers: {
      type: Array,
      default: null,
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fetching: false,
      selectedUser: null,
      users: [],
      query: null,
    };
  },
  watch: {
    selectedUser(value) {
      if (!value || value.length === 0) {
        this.$emit("selected", null);
        return;
      }
      this.$emit("selected", value[0]);
      this.users = [];
    },
  },
  methods: {
    ...mapActions(["getUsers"]),
    fetchUsers: debounce(function (query) {
      this.query = query;
      if (!this.query) {
        this.users = [];
        return;
      }
      this.fetching = true;
      this.getUsers({ emailAddress: query }).then((users) => {
        this.users = sortBy(users.items, (user) => user.name.toLowerCase());
        this.fetching = false;
      });
    }, 200),
    userIsDisabled(user) {
      if (!this.disabledUsers) {
        return false;
      }
      return this.disabledUsers.some((u) => u["@id"] === user["@id"]);
    },
    checkIfAllowed(user) {
      return !this.userIsDisabled(user);
    },
  },
};
</script>
