<template>
  <div>
    <CustomInput
      v-model="contact.name"
      :label="$t('name')"
      :disabled="disabled"
    ></CustomInput>

    <CustomInput
      v-model="contact.emailAddress"
      :label="$t('email_contact')"
      :disabled="disabled"
    ></CustomInput>

    <CustomInput
      v-model="contact.phoneNumber"
      :label="$t('phone')"
      :disabled="disabled"
    ></CustomInput>
  </div>
</template>

<script>
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: { CustomInput },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
