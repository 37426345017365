<template>
  <div>
    <CustomInput
      v-model="address.address"
      :label="$t('address')"
      :disabled="disabled"
    ></CustomInput>

    <CustomInput
      v-model="address.postcode"
      :label="$t('postcode')"
      :disabled="disabled"
    ></CustomInput>

    <CustomInput
      v-model="address.city"
      :label="$t('city')"
      :disabled="disabled"
    ></CustomInput>

    <CustomInput
      v-model="address.state"
      :label="$t('state')"
      :disabled="disabled"
      :required="false"
    ></CustomInput>

    <b-field horizontal :label="$t('country')" :label-for="`country${_uid}`">
      <b-select
        :id="`country${_uid}`"
        v-model="address.country"
        :disabled="disabled"
        required
      >
        <option v-for="c in countries" :key="c.code" :value="c.code">
          {{ c.name }}
        </option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
import CustomInput from "../../../components/forms/CustomInput";
import Countries from "../../../helpers/Countries";

export default {
  components: { CustomInput },
  props: {
    address: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      countries: Countries.all,
    };
  },
};
</script>
